.App {
  text-align: center;
  font-family: Roboto;
}

body{
  font-family: "Roboto";
}

svg {
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

hr{
  margin-bottom: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.formBack{
  min-height: calc(100vh - 114px);
  background: #ededf2;
}

.recommended{
  color: grey;
}

.headerLink{
  display: inline-block;
  padding: 0px 10px;
  font-weight: 100;
  color: #5b5b5b;
  cursor: pointer;
  margin: 0px;
}

.headerLink:hover{
  color: black;
}

.equipmentTag{
  display: inline-block;
}

.marker {
  transform: perspective(40px) rotateX(20deg) rotateZ(-45deg);
  transform-origin: 50% 60%;
  border-radius: 50% 50% 50% 0;
  padding: 0 3px 3px 0;
  width: 30px;
  height: 30px;
  background: #ed1f34;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -2.2em 0 0 -1.3em;
  -webkit-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  -moz-box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
  box-shadow: -1px 1px 4px rgba(0, 0, 0, .5);
}

.marker:after {
  content: '';
  width: 1.5em;
  height: 1.5em;
  margin: 0.8em 0 0 .7em;
  background: #ffffff;
  position: absolute;
  border-radius: 50%;
    -moz-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
  -moz-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
  -webkit-box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
  box-shadow: inset -2px 2px 4px hsla(0, 0, 0, .5);
}
.beacon {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 3em;
  width: 3em;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1
}

.beacon:before,
.beacon:after {
  position: absolute;
  content: "";
  height: 3em;
  width: 3em;
  left: 0;
  top: 0;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 0 0 0 3px #ed1f34;
  -webkit-animation: active 2s infinite linear;
  -moz-animation: active 2s infinite linear;
  animation: active 2s infinite linear;
}

.beacon:after {
  -moz-animation-delay: 1s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

@-webkit-keyframes active {
  0% {
    -webkit-transform: scale(.1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes active {
  0% {
    transform: scale(.1);
    transform: box-shadow(0px 0px 0px 1px #ed1f34);
    opacity: 1;
  }
  100% {
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes blur {
	0%		{text-shadow:  0 0 100px #000000; opacity:0;}
	5%		{text-shadow:  0 0 90px #000000;}
	15%		{opacity: 1;}
  20%		{text-shadow:  0 0 0px #000000;}
  100%	{text-shadow:  0 0 0px #000000; opacity:1;}
}

@-webkit-keyframes blur {
	0%		{text-shadow:  0 0 100px #000000; opacity:0;}
	5%		{text-shadow:  0 0 90px #000000;}
	15%		{opacity: 1;}
	20%		{text-shadow:  0 0 0px #000000;}
  100%	{text-shadow:  0 0 0px #000000; opacity:1;}
}

.blur {
	color: transparent;
	animation: blur 10s forwards;
	-webkit-animation: blur 10s forwards;
  animation-fill-mode: forwards;
}

.blur:nth-child(1) {
	animation-delay: 0.1s;
	-webkit-animation-delay: 0.1s;
}
.blur:nth-child(2) {
	animation-delay: 2s;
	-webkit-animation-delay: 2s;
}

.blurText {
	color: transparent;
	animation: blur 10s forwards;
	-webkit-animation: blur 10s forwards;
  animation-fill-mode: forwards;
  animation-delay: 4s;
	-webkit-animation-delay: 4s;
}

.blurText:nth-child(1) {
	animation-delay: 6s;
	-webkit-animation-delay: 6s;
}

.star{
  position: absolute;
  left: -20px;
  top: 0px;
  font-size: 25px;
}

.equipInfo{
  padding: 10px;
  position: relative;
}

.equipInfo h4{
  margin: 0px;
}

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  width: 50%;
  margin: 1em auto;
  text-align: left;
}
.geosuggest__input {
  width: 100%;
  border: 2px solid transparent;
  box-shadow: 0 0 1px #3d464d;
  padding: .5em 1em;
  -webkit-transition: border 0.2s, box-shadow 0.2s;
          transition: border 0.2s, box-shadow 0.2s;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
          transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: .5em .65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 22px;
  top: 22px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #f7f7f7;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.menu-item{
  text-decoration: none;
  color: black;
}
